import React from 'react';
import {checkData} from "../../helper";
import {Col, Container, Row} from "react-bootstrap";
import {mediaBaseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";

function Projectlog({data}) {
    return (
        <div className="projectlog_section p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={5} xl={5} xxl={5} className="my-auto offset-lg-1">
                        <h2>{checkData(data, 'project_log_title')}</h2>
                        <div>{HtmlParser(checkData(data, 'project_log_description'))}</div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto text-center">
                        <img
                            src={`${mediaBaseURL}${checkData(data, 'project_log_image')}`}
                            className="img-fluid"
                            alt={checkData(data, 'project_log_title')}/>
                    </Col>

                </Row>
                <Row className="pt-100">
                    <Col xs={12} md={10} lg={9} xl={9} xxl={9} className="my-auto offset-lg-1">
                        <div className="item_projects">
                            <h2 className="item_number">01</h2>
                            <div className="item_content">
                                <h3>Decentralized Organization</h3>
                                <p>SUNN DAO is modernizing the inadequate food, trash, and energy sources by creating a
                                    sustainable, decentralized organization (DAO). To better serve professionals and
                                    amateurs interested in the environment and energy preservation, TekRevol
                                    collaborated
                                    with SUNN DAO to develop smart contracts and payment systems for 2 NFT
                                    collections.</p>
                            </div>
                        </div>
                        <div className="item_projects">
                            <h2 className="item_number">02</h2>
                            <div className="item_content">
                                <h3>Ecosystem</h3>
                                <p>We linked the cross-mint for credit card payments. It was necessary to develop a
                                    platform for an NFT collection with a smart contract and integrate features that
                                    would support and promote the creation of a seamless payment ecosystem for customers
                                    purchasing conference tickets.</p>
                            </div>
                        </div>
                        <div className="item_projects">
                            <h2 className="item_number">03</h2>
                            <div className="item_content">
                                <h3>Metadata Developed Contracts</h3>
                                <p>We analyzed the provided design arts and checked their feasibility for NFT
                                    collections creations and smart contracts. Our work and analysis generated the
                                    collections and their metadata, which we linked with our developed contracts to be
                                    used for cross-mint payment solutions.</p>
                            </div>
                        </div>
                        <div className="item_projects">
                            <h2 className="item_number">04</h2>
                            <div className="item_content">
                                <h3>NFT Creations</h3>
                                <p>Our work resulted in creating 2 NFT creations for SUN DAO ONE (SUNN Flare) for the
                                    DAO membership and ticket access to the NFT to HOC conference.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Projectlog;